<template>
  <div v-if="p" class="w-100 fixed position-relative">
    <Player360
        v-if="showVideo360 || showVideo"
        :url="video.stream_hls"
        :id="video.video_id"
        :cover="this.autoplay ? video.cover : false"
        :autoplay="this.autoplay"
        :token="loginInfo.token"
        @exit="exit()"
        :options="[]"
        :showOptions="true"
        @handleVideo="() => {}"
        :type="video.video_type"
        :leftFix="true"
        :plain="showVideo"
        :reloadOnLock="true"
    />
  </div>
  <div v-else class="Elisa" :style="{backgroundImage: backgroundStyle}">
    <div v-if="true" class="Elisa__head">
      <div class="Elisa__head__mainTitle">
        Per acquistare<br />“La Stanza del Tempo Perso” di Elisa<br />
        <span class="yellow upperSmaller">SCEGLI IL CIRCUITO</span>

      </div>
    </div>
    <div class="Elisa__buttons">
      <a
          class="Elisa__buttons__button"
          id="elisa_ticketone"
          onclick="gtag_report_conversion('https://www.ticketone.it/artist/elisa/la-stanza-del-tempo-perso-di-elisa-3166900/', 'AW-10943913509/j-dACPfIm80DEKW8u-Io')"
      >
        TicketOne
      </a>
      <a
          class="Elisa__buttons__button"
          id="elisa_ticketmaster"
          onclick="gtag_report_conversion('https://shop.ticketmaster.it/biglietti/acquista-biglietti-nft-stanza-del-tempo-perso-donazione-music-for-the-planet.html', 'AW-10943913509/Vw2OCNvInM0DEKW8u-Io')"
      >
        Ticketmaster
      </a>
    </div>
    <div class="Elisa__trailer">
      <div
          class="Elisa__trailer__play"
          :style="{
          backgroundImage: 'url(https://media.tok.tv/videoplatform/alive/bead1b87-e46f-4cb5-8f06-a9ccaf530744_1080.jpg)'
        }"
          @click="() => {
            loadVideo('7be848b5-0f47-11ed-b620-0aa5f3f79835');
            // loadVideo('c5d9b754-ca06-11eb-915b-06daa3423294');
          }"
      >
        <PlayBtn message="" />
      </div>
    </div>
    <div class="Elisa__nfts">
      <div class="Elisa__nfts__text">
      <p>Riceverai un codice nell’email di conferma dell’ordine da inserire su <a href="https://www.alivemusic.tv/elisabacktothefuture">A-LIVE</a></p>
      <p>Avrai accesso al tuo NFT, un spazio virtuale e immersivo, accompagnato da musiche e immagini sovrannaturali, un luogo senza tempo dove perdere e ritrovare sé stessi.</p>
      <p>Acquistando l’NFT “La Stanza del Tempo Perso”, doni il tuo contributo a <b>Music For The Planet</b>, la raccolta fondi lanciata da Elisa che ha come obiettivo per il 2025 la messa a dimora di 9 milioni di alberi in tutta Italia per contrastare la crisi climatica.</p>
      <p>Per un’esperienza a 360° ancora più immersiva, prova i <b>visori VR</b> di A-LIVE e viaggia con la realtà virtuale! Li trovi all’interno del Green Village. Sono facili da montare e compatibili con tutti gli smartphones.</p>
      <p class="smaller"><b>Cosa sono gli NFT?</b> Un NFT è un certificato di proprietà digitale, registrato su blockchain e garantito tramite smart contract. Gli NFT di A-LIVE sono creati su blockchain green EOS, che lavora con il Delegated Proof of Stake <b>(DPoS)</b>, un algoritmo di consenso sostenibile ed ecologico che riduce al minimo il consumo energetico e ottimizza la produzione di blocchi.</p>
      <p class="smaller">Music For The Planet è un progetto di Music Innovation Hub e AWorld a favore di Legambiente, con il sostegno di ASviS, Unione Buddhista Italiana e Connect4Climate.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import get from 'lodash.get';
import Player360 from '@/components/Player360.vue';
import PlayBtn from '../glyphs/PlayBtn';

export default {
  name: "Elisa",
  components: {
    Player360,
    PlayBtn,
  },
  data() {
    return {
      text: false,
      needFix: false,
      nftInfo: false,
      playerActive: false,
      cartiglio: false,
      p: false,
      video: false,
      showVideo360: false,
      showVideo: false,
      rbv: false,
      interval: false,
      writing: false,
      writingTo: false,
      autoplay: false,
    };
  },
  methods: {
    ...mapMutations(['appReady', 'setLoginInfo', 'redirectToIP']),
    exit() {
      this.p = false;
      this.needFix = false;
    },
    loadVideo(id) {
      // eslint-disable-next-line no-debugger
      debugger;
      const token = get(this.loginInfo, 'token');
      this.autoplay = true;
      const that = this;
      const ep = `/video/${id}`;
      this.api.get(ep, {
        headers: {
          'x-tok-token': token,
          'Accept-Language': this.locale,
        },
      })
          .then((response) => {
            that.p = true;
            // eslint-disable-next-line no-debugger
            debugger;
            that.video = response.data;
            if (that.video.video_360 || that.video.title.includes('360')) {
              that.showVideo360 = true;
              that.showVideo = false;
            } else {
              that.showVideo360 = false;
              that.showVideo = true;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
  },
  computed: {
    ...mapState([
      'me',
      'loginInfo',
      'locale',
      'api',
      'browser',
      'md',
      // 'nftInfo',
    ]),
    backgroundStyle() {
      const img = 'LANDING_ELISA.jpg';
      return `url(https://media.tok.tv/alive/assets/static/img/${img})`;
    },
  },
  mounted() {
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    // eslint-disable-next-line no-undef
    fbq('init', '591775245646534');
    // eslint-disable-next-line no-undef
    fbq('track', 'PageView');
    this.appReady(true);
    // eslint-disable-next-line no-debugger
    debugger;
    let status = this.$cookies.get(`${window.TOK_NS}storageAccessData`);
    if (!status || status == null) {
      status = this.$ls.get('storageAccessData', false);
    }
    if (status && status != null) {
      this.setLoginInfo(status);
    } else {
      this.redirectToIP({
        router: this.$router,
        registered: false,
        event: 'landing-elisa',
      });
    }
  },

}
</script>

<style lang="scss" scoped>
.Elisa {
  background-color: #269169;
  // height: 100vh;
  min-height: 100vh;
  background-size: 100%;
  background-repeat: no-repeat;
  @media screen and (max-width: 2000px) {
    background-size: cover;
    // background-size: contain;
  }
  @media screen and (max-width: 800px) {
    // background-size: 150%;
    background-size: cover;
  }
  @media screen and (max-width: 600px) {
    // background-size: 250%;
    background-size: cover;
  }
  @media screen and (min-width: 2000px) {
    background-size: 2000px;
  }
  background-position: top center;
  display: flex;
  flex-direction: column;


  &__head {
    color: #FFFFFF;

    &__mainTitle {
      font-family: Montserrat;
      font-weight: 700;
      padding-top: 40px;
      margin: 0 auto;
      font-size: 45px;
      width: 100%;
      max-width: 1000px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-shadow: 0 0 5px rgba(0, 0, 0, 1);
      line-height: 120%;

      .img1 {
        width: 90%;
        margin-top: 40px;
      }

      .img2 {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 5%;
      }

      .img3 {
        width: 30%;
      }

      &__infos {
        display: flex;
        @media screen and (max-width: 600px) {
          flex-direction: column;
        }
        flex-direction: row;
        justify-content: space-between;
        padding: 0 25px;

        &__what {
          @media screen and (max-width: 600px) {
            text-align: center;
            margin-bottom: 10px;
          }
          text-shadow: 0 0 5px rgba(0, 0, 0, 1);
          font-size: 25px;
        }

        &__where {
          @media screen and (max-width: 600px) {
            text-align: center;
          }
          text-shadow: 0 0 5px rgba(0, 0, 0, 1);
          font-size: 25px;
          text-align: right;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    // margin-bottom: 40px;
    &__button {
      cursor: pointer;
      font-family: Montserrat;
      font-weight: 700;
      background-color: #F0DC00;
      color: black;
      font-size: 18px;
      margin: 20px;
      padding: 20px 40px;
      width: 150px;
      display: flex;
      justify-content: center;
      border-radius: 5px;
      transition: all 0.3s ease;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
      &:hover{
        box-shadow: 0 0 35px rgba(0, 0, 0, 0.5);
        background-color: lighten(#F0DC00, 20%);
        text-decoration: none;
      }
    }
  }
  &__nfts {
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    &__text {
      text-align: center;
      padding: 40px 20px;
      color: white;
      width: 100%;
      max-width: 1000px;
      text-shadow: 0 0 5px rgba(0, 0, 0, 1);
      font-family: Montserrat;
      p {
        margin-bottom: 20px;
        line-height: 140%;
        a {
          color: #F0DC00;
        }
        b {
          font-weight: bold;
        }
      }
    }
  }
  &__trailer {
    display: flex;
    justify-content: center;
    &__play {
      position: relative;
      margin: 30px 30px 30px 30px;
      font-size: 18px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      width: 200px;
      height: calc(200px * 0.75);
      padding: 15px 30px;
      background-color: rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      border: 2px solid #FFFFFF;
      color: #FFFFFF;
      cursor: pointer;
      font-weight: bold;
      text-shadow: 0 0 10px rgba(0, 0, 0, 1);
      background-size: cover;
      background-position: center;
    }
  }
}
.smaller {
  font-size: 85%;
}
.upperSmaller {
  font-size: 80%;
  margin-top: 40px;
}
.yellow {
  color: #F0DC00;
}
</style>
